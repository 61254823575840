import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import { Button } from '@forma/forma-ui-kit';
import TariffsItem from './TariffsItem';

import styles from './tariffs.module.css';

const DEFAULT_TARIFF_ID = 'tariff_business';
const DEFAULT_TARIFF_DURATION = 180;

const Tariffs = ({ items }) => {
  const { t } = useTranslation();
  const [ selectedTariff ] = useState(DEFAULT_TARIFF_ID);
  const [ selectedDuration, setSelectedDuration ] = useState(DEFAULT_TARIFF_DURATION);
  const [ selectedMembers, setMembers ] = useState(0);

  if (!items) return null;

  const selectedTariffItem = Object.values(items).find(({ id }) => id === selectedTariff);

  return (
    <div className={styles.root}>
      <div className={classNames(styles.container, 'container')}>
        <div className={styles.header}>
          <div className={styles.headerButtons}>
            {selectedTariffItem?.patterns.map(({ id, canChoose, duration, discount }) => {
              if (!canChoose) return null;
              return (
                <Button
                  viewStyle={selectedDuration === duration ? 'secondary' : 'tertiary'}
                  className={classNames(styles.headerButton, selectedDuration === duration && styles.active)}
                  onClick={() => setSelectedDuration(duration)}
                  key={id}
                >
                  {duration === 360 ? t('count_years', { count: 1 }) : t('count_months', { count: duration / 30 })}
                </Button>
              );
            })}
          </div>
        </div>
        <div className={styles.content}>
          {<div className={styles.tariffs}>
            {Object.values(items).map(item => {
              if (!item?.patterns.find(({ canChoose }) => canChoose)) return null;
              return (
                <TariffsItem
                  {...item}
                  selectedMembers={selectedMembers}
                  selectedDuration={selectedDuration}
                  onChangeMembers={setMembers}
                  key={item.id}
                />
              );
            })}
          </div>}
        </div>
      </div>
    </div>
  );
};

export default Tariffs;
