import classNames from 'classnames';
import Slider from 'react-slick';

import styles from './form-helps.module.css';

const FormHelps = ({ title, items }) => {
  return (
    <div className={styles.root}>
      <div className={classNames(styles.container, 'container')}>
        <div className={styles.header}>
          <h2 className={styles.title}>{title}</h2>
        </div>
        <Slider
          className={styles.slider}
          arrows={false}
          dots
          infinite={false}
          slidesToShow={5}
          responsive={[
            {
              breakpoint: 1025,
              settings: { slidesToShow: 3 }
            },
            {
              breakpoint: 800,
              settings: { slidesToShow: 2 }
            },
            {
              breakpoint: 575,
              settings: { slidesToShow: 1 }
            },
          ]}
        >
          {items.map(({ name, description, image }, index) => (
            <div className={styles.item} key={index}>
              <img className={styles.itemIcon} src={image} width="80px" alt="" />
              <div className={styles.itemContent}>
                <div className={styles.itemTitle}>{name}</div>
                <div className={styles.itemDescription}>{description}</div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default FormHelps;
