import { useContext } from 'react';
import { ReactSVG } from 'react-svg';
import { Trans, useTranslation } from 'next-i18next';
import { useCookie } from 'next-cookie';
import Link from '@/components/Link';
import { Button, Quantity, ThemeContext } from '@forma/forma-ui-kit';
import formatPrice from '@/helpers/formatPrice';

import styles from './tariffs-item.module.css';

const TariffPrices = ({
  durationPrice, durationUserPrice, oldPrice, discount, maxUserPrice, currency, canAddUsers, selectedMembers, selectedDuration
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.pricesBlock}>
      {!!(canAddUsers && selectedMembers) && (
        <div className={styles.priceCalc}>
          {formatPrice(durationPrice, currency)} + {formatPrice(durationUserPrice * selectedMembers, currency)} =
        </div>
      )}
      <div className={styles.prices}>
        <div className={styles.price}>
          {canAddUsers ? (
            formatPrice(durationPrice + durationUserPrice * selectedMembers, currency)
          ) : (
            formatPrice(durationPrice, currency)
          )}
          <span className={styles.priceDuration}>/ {t('per_month')}</span>
        </div>
        {oldPrice !== durationPrice && (
          <div className={styles.oldPrice}>
            {formatPrice(oldPrice + maxUserPrice*selectedMembers, currency)}
          </div>
        )}
      </div>
      {!!discount && (
        <div className={styles.economy}>
          <Trans
            i18nKey="discount_by_pay"
            values={{
              discount: discount + '%',
              months: t('count_months', { count: selectedDuration / 30 })
            }}
          />
        </div>
      )}
    </div>
  );
};

const TariffsItem = ({
  id, name, patterns, canAddUsers, usersLimit, selectedDuration, selectedMembers, onChangeMembers
}) => {
  const cookie = useCookie();
  const { t } = useTranslation();
  const { currencies, viewport } = useContext(ThemeContext);
  const currency = currencies.ru;

  const pattern = patterns.find(({ duration }) => duration === selectedDuration);
  const durationMonths = pattern ? pattern.duration / 30 : 0;
  const durationBasePrice = pattern ? pattern.basePrice / durationMonths : 0;
  const durationPrice = pattern ? pattern.price / durationMonths : 0;
  const durationUserPrice = (pattern?.userPrice && canAddUsers) ? pattern.userPrice / durationMonths : 0;
  const maxUserPrice = Math.max(...patterns.map(({ duration, userPrice }) => userPrice ? userPrice / (duration / 30) : 0));

  const handleClickTry = () => {
    const date = new Date();
    cookie.set('tariff', JSON.stringify({
      tariff: id,
      users: selectedMembers,
      duration: selectedDuration,
    }), {
      path: '/',
      expires: new Date(date.setDate(date.getDate() + 7)),
      domain: window.location.hostname
    });
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.title}>{name}</div>
        <div className={styles.description}>{t('up_to_active_users', { count: usersLimit })}</div>
      </div>

      <div className={styles.info}>
        <TariffPrices
          durationPrice={durationPrice}
          durationUserPrice={durationUserPrice}
          oldPrice={durationBasePrice}
          currency={currency}
          canAddUsers={canAddUsers}
          selectedMembers={selectedMembers}
          selectedDuration={selectedDuration}
          maxUserPrice={maxUserPrice}
          discount={pattern.discount}
        />
      </div>

      {canAddUsers && (
        <div className={styles.additional}>
          <div className={styles.additionalDescr}>
            {t('additional_accounts_over_tariff')} - {formatPrice(durationUserPrice, currency)} / {t('month')}
          </div>
          <div className={styles.additionalCount}>
            <Quantity value={selectedMembers} onChange={onChangeMembers} />
          </div>
        </div>
      )}

      <div className={styles.buttons}>
        <Button
          as={Link}
          viewStyle="secondary"
          onClick={handleClickTry}
          // href={`${lkUrl}/register?lng=${i18n.language}`}
          href="#request_title"
          className={styles.button}
          iconClassName={styles.buttonIcon}
          icon={<ReactSVG src="/icons/chevron-right.svg" wrapper="span" />}
          size="small"
          fullWidth
        >
          {t('try')}
        </Button>
        <div className={styles.notice}>{t('first_days_free')}</div>
      </div>
    </div>
  );
};

export default TariffsItem;
