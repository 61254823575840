import { ReactSVG } from 'react-svg';
import Slider from 'react-slick';
import { Button, ButtonsContainer } from '@forma/forma-ui-kit';
import Link from '@/components/Link';
import Video from '@/components/Video';

import styles from './only-in-form.module.css';

const OnlyInForm = ({ title, button, items, itemsCountDesktop, downloadButton }) => {
  return (
    <div className={styles.root}>
      <div className="container">
        <div className={styles.header}>
          <h2 className={styles.title}>{title}</h2>
          <Button
            as={Link}
            viewStyle="text"
            className={styles.headButton}
            href={button.href}
            icon={<ReactSVG src={button.icon} wrapper="span" />}
            reversed
          >
            {button.text}
          </Button>
        </div>
        <Slider
          className={styles.slider}
          arrows={false}
          dots
          infinite={false}
          slidesToShow={itemsCountDesktop}
          responsive={[
            {
              breakpoint: 1025,
              settings: { slidesToShow: 3 }
            },
            {
              breakpoint: 800,
              settings: { slidesToShow: 2 }
            },
            {
              breakpoint: 575,
              settings: { slidesToShow: 1 }
            },
          ]}
        >
          {items.map(({ name, image, description, video }, index) => (
            <div className={styles.item} key={index}>
              {video && <Video src={video} className={styles.itemVideo} showControls={false} autoPlay loop />}
              {image && <img className={styles.itemImage} src={image} alt="" />}
              <div className={styles.itemName} dangerouslySetInnerHTML={{ __html: name }} />
              {description && (
                <div className={styles.itemDescription} dangerouslySetInnerHTML={{ __html: description }} />
              )}
            </div>
          ))}
        </Slider>
        <ButtonsContainer className={styles.buttons}>
          <Button
            as="a"
            viewStyle="secondary"
            className={styles.button}
            href={downloadButton.href}
            target="_blank"
          >
            {downloadButton.text}
          </Button>
        </ButtonsContainer>
      </div>
    </div>
  );
};

export default OnlyInForm;
