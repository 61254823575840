import { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'next-i18next';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { Button, phonemasks, Input, PhoneInput, SocialSelect } from '@forma/forma-ui-kit';
import Link from '@/components/Link';
import ControlField from '@/components/fields/ControlField';
import DateTimeInput from '@/components/fields/DateTimeInput';
import { queries } from '@/helpers/api';
import { analytics } from '@/helpers/analytics';

import styles from './request-form.module.css';

const RequestForm = ({ title, subtitle, onSubmit, isSendSuccess, isSendError, saveResult }) => {
  const { t, i18n } = useTranslation();
  const { register, handleSubmit, control, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
    defaultValues: {
      social: 'Telegram'
    }
  });
  const [ phoneValidation, setPhoneValidation ] = useState('');
  const [ timeslots, setTimeslots ] = useState(null);

  useEffect(() => {
    if (i18n && phonemasks) {
      const code = i18n.language === 'en' ? 'US' : i18n.language.toUpperCase();
      const mask = phonemasks.find(({ iso }) => iso === code);
      if (mask) setPhoneValidation(mask.code + mask.mask);
    }
  }, [i18n]);

  useEffect(() => {
    const getTimeslots = async () => {
      const res = await queries.getTimeslots();
      setTimeslots(res, i18n.language);
    };
    getTimeslots();
  }, [i18n.language]);

  const handleChangeMask = ({ mask, code }) => {
    setPhoneValidation(code + mask);
  };

  return (
    <form className={styles.root} onSubmit={handleSubmit(onSubmit)}>
      {(isSendSuccess || isSendError) ? (
        isSendSuccess ? (
          <div className={styles.result}>
            <div className={styles.resultTitle}>
              <Trans
                i18nKey="you_signed_up_for_demonstration"
                components={{ accent: <span className="accent-text" /> }}
              />
              <br />
              <div className="accent-text">
                {moment(saveResult.date).utcOffset(saveResult.utc).format('DD.MM.yyyy')} {t('year_short')} {t('at')} {moment(saveResult.date).utcOffset(saveResult.utc).format('HH:mm')}
              </div>
            </div>
            <div className={styles.resultSubtitle}>{t('demonstration_time_notice')}</div>
            <div className={styles.resultSubtitle}>{t('demonstration_description')}</div>
            <div className={styles.buttons}>
              <Button
                as={Link}
                className={styles.button}
                viewStyle="primary"
                size="medium"
                href="/"
              >
                {t('go_to_homepage')}
              </Button>
            </div>
          </div>
        ) : (
          <div className={styles.result}>
            <div className={styles.title}>
              {t('contacts_form_send_error')}
            </div>
          </div>
        )
      ) : (
        <>
          <h1 className={styles.title} id="request_title">
            {title}
          </h1>
          <div className={styles.subtitle}>{subtitle}</div>
          <div className={styles.fields}>
            <ControlField
              id="name"
              component={Input}
              name="name"
              placeholder={t('your_name_surname')}
              control={control}
              error={errors.name}
              errorMessage={errors.name?.message}
              {...register('name', {
                required: t('please_fill_the_field'),
                minLength: { value: 3, message: t('errors.min_lenght_3') },
                pattern: { value: /^[^0-9]+$/, message: t('errors.name_has_numbers') }
              })}
              containerClass={styles.inputContainer}
            />
            <ControlField
              id="email"
              component={Input}
              name="email"
              placeholder={t('email')}
              control={control}
              error={errors.email}
              errorMessage={errors.email?.message}
              {...register('email', {
                required: t('please_fill_the_field'),
                minLength: { value: 3, message: t('errors.min_lenght_3') },
                pattern: { value: /\S+@\S+\.\S+/, message: t('errors.email_not_valid') }
              })}
              containerClass={styles.inputContainer}
            />
            <ControlField
              id="company_name"
              component={Input}
              name="company_name"
              placeholder={t('yours_company_name')}
              control={control}
              error={errors.company_name}
              errorMessage={errors.company_name?.message}
              {...register('company_name', {
                required: t('please_fill_the_field'),
                minLength: { value: 3, message: t('errors.min_lenght_3') }
              })}
              containerClass={styles.inputContainer}
            />
            <ControlField
              id="date_time"
              component={DateTimeInput}
              name="date_time"
              placeholder={t('select_date_time_for_presentation')}
              control={control}
              error={errors.date_time}
              errorMessage={errors.date_time?.message}
              {...register('date_time', {
                required: t('please_fill_the_field'),
                validate: {
                  checkSlot: value => !!value.slot || t('errors.select_slot'),
                }
              })}
              containerClass={styles.inputContainer}
              timeslots={timeslots}
            />
            <ControlField
              id="phone"
              component={PhoneInput}
              masks={phonemasks}
              defaultMaskCode={i18n.language === 'en' ? 'US' : i18n.language.toUpperCase()}
              onChangeMask={handleChangeMask}
              name="phone"
              placeholder={t('phone_number')}
              control={control}
              error={errors.phone}
              errorMessage={errors.phone?.message}
              {...register('phone', {
                required: t('please_fill_the_field'),
                minLength: { value: phoneValidation.length, message: t('errors.min_lenght_phone') }
              })}
              autoComplete="off"
              containerClass={styles.inputContainer}
              className={styles.phoneInputInput}
            />
            <ControlField
              id="social"
              component={SocialSelect}
              name="social"
              label={t('where_contact_you_presentation')}
              control={control}
              error={errors.social}
              errorMessage={errors.social?.message}
              {...register('social', { required: t('please_fill_the_field') })}
              containerClass={styles.inputContainer}
            />
          </div>
          <div className={styles.buttons}>
            <Button
              type="submit"
              className={styles.button}
              viewStyle="primary"
              size="medium"
              onClick={analytics.sendRequestForm}
              disabled={!isValid}
            >
              {t('sign_up_demo')}
            </Button>
          </div>
          <div className={styles.notice}>
            <Trans
              i18nKey="user_agreement_form_notice"
              components={{ privacy: <Link href="/privacy" />, license: <Link href="/license" />, button: t('sign_up_demo') }}
            />
          </div>
        </>
      )}
    </form>
  );
};

export default RequestForm;
