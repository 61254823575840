import { useContext } from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { Button, ButtonsContainer, ThemeContext } from '@forma/forma-ui-kit';
import Link from '@/components/Link';
import { MainContext } from '@/context/MainContext';

import styles from './main-banner.module.css';

const MainBanner = ({ title, description, button, image, icons, background, notice }) => {
  const router = useRouter();
  const { viewport } = useContext(ThemeContext);
  const { main } = useContext(MainContext);
  const isPhone = ['phone', 'tabletS'].includes(viewport);

  return (
    <div className={styles.root} style={{ backgroundImage: background && `url('${background}')`}}>
      <div className="container">
        {!isPhone && (
          <div className={styles.links}>
            {main.homepageMenu.map(({ id, name, href }) => {
              const checkPath = href === '/' ? '/[locale]' : '/[locale]' + href;
              const isActive = router.pathname === checkPath;

              return (
                <Link
                  href={href}
                  className={classNames(styles.link, isActive && styles.active)}
                  key={id}
                >
                  {name}
                </Link>
              );
            })}
          </div>
        )}
        <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
        {description && (
          <div className={styles.subtitle} dangerouslySetInnerHTML={{ __html: description }} />
        )}
        {image && (
          <div className={styles.imageWrap}>
            <img
              className={styles.image}
              src={image}
              alt=""
            />
          </div>
        )}
        {icons && (
          <div className={styles.icons}>
            {icons.map(href => (
              <img src={href} className={styles.icon} alt="" key={href} />
            ))}
          </div>
        )}
        <ButtonsContainer className={styles.buttons}>
          <Button
            as={Link}
            viewStyle="primary"
            className={styles.button}
            href={button.href}
            size="small"
          >
            {button.text}
          </Button>
        </ButtonsContainer>
        <div className={styles.notice}>
          {notice}
        </div>
      </div>
    </div>
  );
};

export default MainBanner;
