import classNames from 'classnames';
import styles from './social-media-slider.module.css';

const SocialMediaSlider = ({ title, items }) => {
  return (
    <div className={styles.root}>
      <div className={classNames(styles.container, 'container')}>
        <div className={styles.title}>{title}</div>
        <div className={styles.marquee}>
          <div className={styles.marqueeContent}>
            {items.map(({ id, name, icon }) => (
              <img src={icon} className={styles.icon} alt={name} key={id} />
            ))}
          </div>
          <div className={styles.marqueeContent} aria-hidden="true">
            {items.map(({ id, name, icon }) => (
              <img src={icon} className={styles.icon} alt={name} key={id} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaSlider;
