import { useState } from 'react';

import styles from './faq.module.css';

const FaqItem = ({ question, answer }) => {
  const [ isOpen, setOpen ] = useState(false);

  return (
    <div className={styles.item}>
      <div className={styles.itemHeader} onClick={() => setOpen(!isOpen)}>
        <img src="/icons/arrow-down.svg" className={styles.itemArrow} alt="" />
        <div className={styles.itemTitle}>{question}</div>
      </div>
      {isOpen && <div className={styles.itemContent} dangerouslySetInnerHTML={{ __html: answer }} />}
    </div>
  );
};

const Faq = ({ items }) => {
  return (
    <div className={styles.root}>
      <div className="container">
        <div className={styles.header}>
          <h2 className={styles.title}>FAQ</h2>
        </div>
        <div className={styles.items}>
          {items.map(({ question, answer }, index) => (
            <FaqItem question={question} answer={answer} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
